// 获取路由参数
export function getQueryVariable(variable) {
    var queryString = window.location.search.substring(1);
    var vars = queryString.split("&");
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == variable) { return pair[1]; }
    }
    return (false);
}

export function getUrlParameters() {
    var url = location.search; //这一条语句获取了包括问号开始到参数的最后，不包括前面的路径
    if (!url) {
        return null;
    }
    var params = url.substr(1);//去掉问号
    var pa = params.split("&");
    var s = new Object();
    for (var i = 0; i < pa.length; i++) {
        s[pa[i].split("=")[0]] = unescape(pa[i].split("=")[1]);
    }
    console.log('--提取参数', s);
    return s;
}

import { getToken, getRefreshToken } from './auth';
import Cookies from "js-cookie";
import store from '../store'

// 检测token
import { refreshToken } from '../api/login'
export async function refreshTokenFn() {
    let refresh_token = getRefreshToken()
    let token = getToken()
    if (!token && refresh_token) {
        refreshToken(refresh_token).then(res => {
            if(res.code == 200){
                console.log('token失效,自动刷新成功!')
                let seconds = res.data.expires_in - 3600;
                let expires = new Date(new Date() * 1 + seconds * 1000);
                Cookies.set("Admin-Token", res.data.access_token, { expires: expires })
                Cookies.set("Refresh_Token", res.data.refresh_token, 3)
                router.push({ path: '/refreshPage' })
            }else{
                console.log('token失效,自动刷新失败!')
                localStorage.clear()
                router.replace({ path: '/login' })
            }
        })
    } else if(!token && !refresh_token) {
        console.log('token失效,无法自动刷新---!')
        localStorage.clear()
        router.replace({ path: '/login' ,query: { type: 'refresh_token' } })
    }
}

import { userInfo } from '@/api/login'
import router from '../router';

export function getUserInfo() {
    let accessToken = getToken()
    let json = {
        accessToken: accessToken
    }
    userInfo(json).then(res => {
        // 判断是县级登录还是村级登录
        let tenantId = res.data.tenant.id;
        let path = 'tutorials';
        let tenantName = res.data.tenant.name;
        let webState = 'village';
        let tenantCode = res.data.tenant.code;
        //判断用户类型  县，镇，村  421224107000
        if(tenantCode.substring(6) == '000000'){
            localStorage.setItem('xiancode', tenantCode);
            tenantId = res.data.tenant.child[0].child[0].id
            tenantName = res.data.tenant.child[0].child[0].name
            tenantCode = res.data.tenant.child[0].child[0].code
            path = 'county'
            webState = 'county'
            
        }else if(tenantCode.substring(9) == '000'){
            localStorage.setItem('zhencode', tenantCode);
            localStorage.setItem('zhenTenantId', tenantId);
            tenantId = res.data.tenant.child[0].id
            tenantName = res.data.tenant.child[0].name
            tenantCode = res.data.tenant.child[0].code
            localStorage.setItem('tenantIdVown', res.data.tenant.id)
            path = 'town'
            webState = 'town'
            
        }
        // if (res.data.tenant.child.length > 0) {   //县级
        //     tenantId = res.data.tenant.child[0].child[2].id
        //     tenantName = res.data.tenant.child[0].child[2].name
        //     tenantCode = res.data.tenant.child[0].child[2].code
        //     path = 'county'
        //     webState = 'county'
        // } else {
        //     tenantId = res.data.tenant.id
        //     tenantName = res.data.tenant.name
        //     path = 'tutorials'
        //     webState = 'village'
        //     tenantCode = res.data.tenant.code
        // }
        localStorage.setItem('tenantId', tenantId)
        localStorage.setItem('webState', webState)
        localStorage.setItem('tenantName', tenantName)
        localStorage.setItem('tenantCode', tenantCode)
        if(res.data.tenant.child.length>0 && res.data.tenant.child[0].child.length>0){
            orderbyCode(res.data.tenant.child[0].child);
        }
        sessionStorage.setItem('token', JSON.stringify(res.data))
        store.commit('changeActive', 1)
        router.replace({ path: path })
    })
}

function orderbyCode(list){
    for(var i=0;i<list.length-1;i++){
        var part1 = list[i];
        for(var j=i+1;j<list.length;j++){
            var part2 = list[j];
            if(parseInt(part1.code)>parseInt(part2.code)){
                var temp = part1;
                list[i] = part2;
                list[j] = temp;
            }
        }
    }
}

/**
 * 
 * @param {*} token 
 * 检查权限必须使用同步方法
 *//*
export function checkToken(token){
 var ret = false;
 $.ajax({
     type: "get",
     async: false,
     ////"http://192.168.10.154:8080/auth/oauth/check_token", 
     //发布到服务器上，用这个地址    https://demo.eaia.cn/api/auth/oauth/check_token
     url: URL_OAUTH_CHECK_TOKEN, 
     data: {token: token},
     success: function(result){
         console.log('--check_token回调 user_name', result.user_name);
         if(result.code && result.code == 500){//失败
             localStorage.removeItem("token");
             localStorage.removeItem('uid');
         }else{
             localStorage.setItem('token', token);
             localStorage.setItem('uid', result.user_name);
             ret = true;
             
         }
     },
     error: function (msg) {
         console.log('--请求异常', msg);
     }
 });
 return ret;
}*/

export function getDiShiZhouCodeByCunCode(code) {
    var reg = /^[0-9]{12}$/;   /*定义验证表达式*/
    if (!reg.test(code)) {   /*进行验证*/
        console.log(code + '不是12位数字');
        return '';
    }
    /*仙桃市	429004
        潜江市	429005
        天门市	429006
        神农架林区	429021
    */
    var code4bit = code.substr(0, 4);
    if (code4bit === '4290') {
        return code.substr(0, 6);
    } else {
        return code.substr(0, 4) + '00';
    }
}

/**处理身份证号信息，隐藏后6位 */
export function getShortSfzh(sfzh) {
    var _short_sfzh = '';
    if (sfzh) {
        _short_sfzh = sfzh.substr(0, 12) + '******';
    }
    //console.log(_short_sfzh);
    return _short_sfzh;
}

export function getCsrqFromSfzh(sfzh) {
    if (sfzh == '' || sfzh == null) {
        return '';
    }
    var year = sfzh.substr(6, 4);
    var month = sfzh.substr(10, 2);
    var day = sfzh.substr(12, 2);
    return year + '-' + month + '-' + day;
}

export function shortXmTo6Byte(xm) {
    var _xm = '--';
    if (xm) {
        _xm = xm;
    }
    if (xm.length > 5) {
        _xm = xm.substr(0, 6);
    }
    return _xm;
}

export function getRegionFromPoints(geometry) {
    //暂未考虑洞嵌套的复杂面
    var _points = geometry.points;
    var _partTopo = geometry.partTopo;//[1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1]
    var _parts = geometry.parts;//[516, 33, 42, 22, 11, 16, 16, 9, 14, 12, 9, 10]
    var _partIndex = 0;
    var _pCount = 1;
    var _regions = [];
    for (var j = 0; j < _points.length; j++, _pCount++) {
        var _point = _points[j];
        if (_pCount == 1) {
            _regions[_partIndex] = [];
        }
        _regions[_partIndex].push(_point.x);
        _regions[_partIndex].push(_point.y);
        if (_pCount == _parts[_partIndex]) {
            _pCount = 0;
            _partIndex++;
        }
    }
    return _regions;
}

export function getTwoBitDotNumber(numberString) {
    var index = numberString.indexOf('.');
    return numberString.substr(0, index + 3);
}

export function computeDistance(pStart, pEnd) {
    var point1cartographic = Cesium.Cartographic.fromCartesian(pStart);
    var point2cartographic = Cesium.Cartographic.fromCartesian(pEnd);
    var geodesic = new Cesium.EllipsoidGeodesic();
    geodesic.setEndPoints(point1cartographic, point2cartographic);
    var distance = geodesic.surfaceDistance;
    //返回两点之间的距离
    distance = Math.sqrt(Math.pow(distance, 2) + Math.pow(point2cartographic.height - point1cartographic.height, 2));
    return distance;
}

export function getCenterPosition() {
    return window.viewer.camera.pickEllipsoid(new Cesium.Cartesian2(window.viewer.canvas.clientWidth / 2, window.viewer.canvas.clientHeight / 2));
}

export function circleFly() {
    var heading = window.viewer.camera.heading - 0.001;//0.001
    var pitch = window.viewer.camera.pitch;
    var _pStart = getCenterPosition();
    if (!_pStart) {
        return;
    }
    var _pEnd = window.viewer.camera.position;
    var distance = computeDistance(_pStart, _pEnd);
    window.viewer.scene.camera.setView({
        destination: _pStart,
        orientation: {
            heading: heading,
            pitch: pitch,

        }
    });
    window.viewer.scene.camera.moveBackward(distance);
}
//export default {getDiShiZhouCodeByCunCode};
//取坐标的范围
export function getEnvelope(features){
    if(features.length == 0){
        return [];
    }
    var minY = 90;
    var minX = 180;
    var maxY = 0;
    var maxX = 0;
    for (var i = 0; i < features.length; i++) {
        var _feature = features[i];
        var lat = _feature.geometry.center.y;
        var lon = _feature.geometry.center.x;
        minY = Math.min(minY, lat);
        minX = Math.min(minX, lon);
        maxY = Math.max(maxY, lat);
        maxX = Math.max(maxX, lon);
    }
    var x_cha = maxX-minX;
    var y_cha = maxY-minY;
    var max_cha = x_cha;
    if(x_cha<y_cha){
        max_cha = y_cha;
    }
    var center_x = (minX+maxX)/2;
    var center_y = (minY+maxY)/2;
    if(max_cha < 0.001){//范围过小
        minX = center_x - 0.001;
        maxX = center_x + 0.001;
        minY = center_y - 0.001;
        maxY = center_y + 0.001;
    }else{
        minX = center_x - max_cha*0.7;
        maxX = center_x + max_cha*0.6;
        minY = center_y - max_cha*0.6;
        maxY = center_y + max_cha*0.8;
    }
    //console.log('--坐标范围--', minX, minY, maxX, maxY);
    return [minX, minY, maxX, maxY];
}

//取坐标的范围
export function getEnvelopeByList(list){
    if(list.length == 0){
        return [];
    }
    var minY = 90;
    var minX = 180;
    var maxY = 0;
    var maxX = 0;
    for (var i = 0; i < list.length; i++) {
        var _feature = list[i];
        var lon = _feature.shape[0];
        var lat = _feature.shape[1];
        minY = Math.min(minY, lat);
        minX = Math.min(minX, lon);
        maxY = Math.max(maxY, lat);
        maxX = Math.max(maxX, lon);
    }
    var x_cha = maxX-minX;
    var y_cha = maxY-minY;
    var max_cha = x_cha;
    if(x_cha<y_cha){
        max_cha = y_cha;
    }
    var center_x = (minX+maxX)/2;
    var center_y = (minY+maxY)/2;
    if(max_cha < 0.001){//范围过小
        minX = center_x - 0.001;
        maxX = center_x + 0.001;
        minY = center_y - 0.001;
        maxY = center_y + 0.001;
    }else{
        minX = center_x - max_cha*0.7;
        maxX = center_x + max_cha*0.6;
        minY = center_y - max_cha*0.6;
        maxY = center_y + max_cha*0.8;
    }
    //console.log('--坐标范围--', minX, minY, maxX, maxY);
    return [minX, minY, maxX, maxY];
}

//取坐标的范围3
export function getEnvelopeByPoints(points){
    if(points.length == 0){
        return [];
    }
    var minY = 90;
    var minX = 180;
    var maxY = 0;
    var maxX = 0;
    for (var i = 0; i < points.length; i++) {
        var _point = points[i];
        var lon = _point.x;
        var lat = _point.y;
        minY = Math.min(minY, lat);
        minX = Math.min(minX, lon);
        maxY = Math.max(maxY, lat);
        maxX = Math.max(maxX, lon);
    }
    var x_cha = maxX-minX;
    var y_cha = maxY-minY;
    var max_cha = x_cha;
    if(x_cha<y_cha){
        max_cha = y_cha;
    }
    var center_x = (minX+maxX)/2;
    var center_y = (minY+maxY)/2;
    if(max_cha < 0.001){//范围过小
        minX = center_x - 0.001;
        maxX = center_x + 0.001;
        minY = center_y - 0.001;
        maxY = center_y + 0.001;
    }else{
        minX = center_x - max_cha/2;
        maxX = center_x + max_cha/2;
        minY = center_y - max_cha/2;
        maxY = center_y + max_cha/2;
    }
    return [minX, minY, maxX, maxY];
}