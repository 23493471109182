import Vue from 'vue'
import VueRouter from 'vue-router'
import config from '@/config.js';
import {
	Message
} from 'element-ui';
import {
	getToken
} from '../util/auth'
import {refreshTokenFn} from '../util/common'

Vue.use(VueRouter)

let routes = [{
	path: '/',
	name: 'Home',
	component: () => import('../views/Home.vue'),
	redirect: '/tutorials',
	children: [{
		path: 'tutorials',
		name: 'Tutorials',
		pageName: '规划图',
		type: true,
		component: () => import('@/views/tutorials.vue')
	}, {
		path: 'farming',
		name: 'Farming',
		pageName: '生产图',
		type: true,
		component: () => import('@/views/farming.vue')
	}, {
		path: 'govern',
		name: 'Govern',
		pageName: '治理图',
		type: true,
		component: () => import('@/views/govern.vue')
	}, {
		path: 'environment',
		name: 'Environment',
		pageName: '环境图',
		type: true,
		component: () => import('@/views/environment.vue')
	}, {
		path: 'serve',
		name: 'Serve',
		pageName: '服务图',
		type: true,
		component: () => import('@/views/serve.vue')
	}, {
		path: 'party',
		name: 'Party',
		component: () => import('@/views/party.vue')
	}, {
		path: 'county',
		name: 'county',
		component: () => import('@/views/county.vue')
	}, {
		path: 'town',
		name: 'town',
		component: () => import('@/views/town.vue')
	}]
}, {
	path: '/login',
	name: "Login",
	component: () => import('../views/Login.vue')
}, {
	path: '/refreshPage',
	name: "refreshPage",
	component: () => import('../views/refreshPage.vue')
}]

const router = new VueRouter({
	mode: 'history',
	base: 'v2',
	routes
})

router.beforeEach((to, from, next) => {
	console.log('验证', to.query.token)
	if (to.path != '/login') {
		let token = JSON.parse(sessionStorage.getItem('token'))
		if (!token) {
			next({
				path: '/login'
			})
		} else {
			if (!getToken()) {
				refreshTokenFn()
			}
			if (to.name == 'county') { //跳转县级页面
				let webStste = localStorage.getItem('webState')
				if (webStste != 'county') {
					next({
						path: '/'
					})
				}
			}
			next()
		}
	} else {
		next()
	}

})

export default router
