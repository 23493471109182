import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
import './plugins/rem.js'
import axios from 'axios'
import config from './config.js'
import bus from './util/bus.js'

// import * as Cesium from 'cesium'
// const viewer = new Cesium.Viewer('cesiumContainer');

Vue.prototype.$axios = axios;
Vue.prototype.$config = config;
Vue.prototype.$bus = bus;

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
