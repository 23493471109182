<template>
  <div id="app">
    <router-view />
	  <div class="bottomLine"></div>
    </div>
</template>

<script>
import "@/assets/iconfont/iconfont.css"
export default {
  name: 'app',
  components: {},
  mounted(){
    if(localStorage.getItem('changeActive')){
      this.$store.commit('changeActive',localStorage.getItem('changeActive') )
    }
  },
  watch:{

  },
  created(){

  },
  methods: {
  
  }
}

</script>
<style>
	@font-face {
		font-family: 'title-font';
		src: url('./assets/font/1586946283769.ttf');
	}
.el-message__icon{
  font-size: .16rem;
}
</style>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
}

#app {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.page_temp_item_box{
  width: 2.78rem;
  height: 100%;
}
.page_temp {
  width: 4rem;
  height: 100vh;
  position: absolute;
  z-index: 300;
  box-sizing: border-box;
  padding-top: 1.1rem;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.page_temp_left {
  left: 0;
  padding-left: .32rem;
  background: linear-gradient(80deg, #0E2545 10%, transparent 100%);
  // background-image: linear-gradient(to right,rgba(0, 0, 0, 0.6) 80%,rgba(0, 0, 0, 0.1) );
  // background-image: url(./assets/img/login/left_bg.png);
}

.page_temp_right {
  padding-right: .32rem;
  right: 0;
  background: linear-gradient(-80deg, #0E2545 10%, transparent 100%);
  // background-image: linear-gradient(to left,rgba(0, 0, 0, 0.6) 80%,rgba(0, 0, 0, 0.1) );
  // background-image: url(./assets/img/login/right_bg.png);
}

.contentBox {
  // background-color: rgba(255, 255, 255, 0.2);
  width: 100%;
  font-size: .14rem;
  box-sizing: border-box;
  color: #fff;
  position: relative;
}

.split-line {
  width: 100%;
  height: .04rem;
  background-image: linear-gradient(to right, transparent, rgba(255, 255, 255, 0.739), transparent);
  margin: .1rem 0;
  padding: 0 .3rem;
  box-sizing: border-box;
}
// .page_temp_left>div{
//   height: 100%;
//   box-sizing: border-box;
//   // overflow: auto;
//   width: 100%;
// }
.scrollbar::-webkit-scrollbar,
.page_temp_left>div::-webkit-scrollbar {
    width: .04rem;
	background-color: #021633;
}

.scrollbar::-webkit-scrollbar-thumb,
.page_temp_left>div::-webkit-scrollbar-thumb {
    background-color: #317FF5;
    width: .04rem;
    border-radius: .05rem;
}
input::placeholder{
    color: #ddd;
    font-size: .12rem;
}

.bottomLine{
		position: absolute;
		bottom: 10px;
		width: calc(100% - .64rem);
		margin-left: 0.32rem;
		height: 0.2rem;
		box-sizing: border-box;
		// background-image: url("./assets/new_img/pageBottom-ts.png");
		background-repeat: no-repeat;
		background-size: 100%;
		z-index: 999;
	}
</style>
