var URL_ISERVER = '/gis/';
var URL_IUPLOAD = "https://map.eaia.cn/iupload/";  //服务器发布
var URL_IP_TOKEN = 'http://192.168.10.164:8080/';  //内网上用这个地址

module.exports = {
    URL_ISERVER: URL_ISERVER,
    URL_ISERVER_REST_DATA: URL_ISERVER + 'iserver/services/data-pg156/rest/data/featureResults.json?returnContent=true', //&fromIndex=0&toIndex=5000;
    TDT_MAP_TOKEN: 'f7da9024e5d136e8bb74c2d7d83abf69',//'62821db3fe578d3c8bc5e44ddb10aa75',
    MAIN_PG_DATABASE: 'p420000',
    URL_IUPLOAD: URL_IUPLOAD,
    URL_OAUTH_TOKEN: URL_IP_TOKEN +'auth/token/login',   // 新版登录url 164 保康
    URL_QUERY_USER_INFO: URL_IP_TOKEN + 'member/mapInfo/loginInfo',  //取用户信息
    URL_XHJ: '',//小黄姜种植示范基地
    URL_RJHJJC: '/environment/#/home',//人居环境监测
    URL_TSRQGA: '/care/index',//特殊人群关爱
    TENANT_CODE:localStorage.getItem('tenantCode')
}