import axios from "../axios/index"

// 获取token
export const login = (data) => {
    return axios.post('/onemap/login/back?password='+data.password+'&username='+data.username)
}
// 获取用户信息
export const userInfo = (data) =>{
    return axios.get('/onemap/login/info',data)
}
// 校验token
export const checkToken = (token) =>{
    return axios.post('/auth/oauth/check_token?token='+token)
}
// 刷新token接口
export const refreshToken = (data) => {
    return axios.post('/onemap/login/refresh?refreshToken='+data)
}