import Cookies from 'js-cookie'

const TokenKey = 'Admin-Token'

const RefreshTokenKey = 'Refresh_Token'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function getRefreshToken() {
  return Cookies.get(RefreshTokenKey)
}

export function setRefreshToken(time) {
  return Cookies.set(RefreshTokenKey, time)
}

export function removeRefreshToken() {
  return Cookies.remove(RefreshTokenKey)
}
