import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    menu_active:1,
    webState:'village'//village
  },
  mutations: {
    changeActive:function(state,index){
      state.menu_active = index
    }
  },
  actions: {
  },
  modules: {
  }
})
