import axios from 'axios';
import router from '../router';
import qs from 'qs';
import { getToken ,removeToken } from '../util/auth'
import {refreshTokenFn} from '../util/common'
import { Notification } from 'element-ui';
// let host = location.host.split(':').length>1?'http://192.168.10.164:8080/bigscreen':'/api/bigscreen'

// import Notice from 'iview/src/components/notice'
// let urlTitle = location.host.indexOf('localhost') != -1
//     			||location.host.indexOf('127.0.0.1') != -1
//     			?'/proyx':'/api'
let urlTitle = '/api'
// 添加请求拦截器
axios.defaults.withCredentials  = true;
axios.interceptors.request.use(config => {
  return config;
}, error => Promise.reject(error));
// 添加响应拦截器
axios.interceptors.response.use(response => response, error => Promise.resolve(error.response));

function checkStatus(res) {
  return res;
}

function checkCode(res) {
  return res.data;
}

export default {
  post(url, data) {
    return axios({
      method: 'post',
      url: urlTitle+url,
      data: data,
      // timeout: config.timeout,
      withCredentials: true,
      headers:(() => {
				let tokenValue = getToken('Admin-Token')
				let config = {
					'Content-Type': 'application/json; charset=UTF-8',
					'X-Requested-With': 'XMLHttpRequest',
				}
				if (tokenValue) {
					config["Authorization"] = 'bearer' + tokenValue
				}
				return config
			})(),
    }).then(checkStatus).then(checkCode);
    // .then(checkStatus)
  },
  get(url, params) {
    return axios({
      method: 'get',
      url: urlTitle+url,
      params,
      // timeout: config.timeout,
      withCredentials: true,
      headers:(() => {
				let tokenValue = getToken('Admin-Token')
				let config = {
					'Content-Type': 'application/json; charset=UTF-8',
          'X-Requested-With': 'XMLHttpRequest',
				}
				if (tokenValue) {
					config["Authorization"] = 'bearer' + tokenValue
				}
				return config
			})(),
    }).then(checkStatus).then(checkCode);
  },
  // ajaxGet(url, data) {
  //   return axios({
  //     method: 'get',
  //     url: url,
  //     data: data,
  //     timeout: config.timeout,
  //     withCredentials: true,
  //     headers: {
  //       'X-Requested-With': 'XMLHttpRequest',
  //       'Content-Type': 'application/json; charset=UTF-8'
  //     }
  //   }).then(checkStatus).then(checkCode);
  // },
  // postJson(url, data) {
  //   return axios({
  //     method: 'post',
  //     url: url,
  //     data: data,
  //     timeout: config.timeout,
  //     withCredentials: true,
  //     headers: {
  //       'X-Requested-With': 'XMLHttpRequest',
  //       'Content-Type': 'application/x-www-form-urlencoded'
  //     }
  //   }).then(checkStatus).then(checkCode);
  // },
}
